import { Image } from "./image";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Products</h2>
        </div>
        <div className="row p-5">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  style={{ minHeight: "25vh" }}
                  key={`${d.title}-${i}`}
                  className="col-xs-6 col-md-4"
                >
                  {" "}
                  <i className={d.icon}></i>
                  <a href={d.to} rel="nofollow">
                    <img style={{ heigh: 50, width: 50 }} src={d.icon} />
                  </a>
                  <a href={d.to} rel="nofollow">
                    <h3>{d.title}</h3>
                  </a>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
